<template>
  <div class="import-box">
    <el-dialog
      title="Please select a phone number."
      :visible.sync="dialog"
      width="30%"
      center>
      <el-table ref="tables" :data="phones" tooltip-effect="dark" style="width: 100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="Number">
          <template slot-scope="scope">{{ scope.row.number }}</template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">cancel</el-button>
        <el-button type="primary" @click="confirmPhone">ok</el-button>
      </span>
    </el-dialog>
    <el-carousel ref="carousel" trigger="click" :autoplay="false" arrow="never">
      <el-carousel-item v-for="item in 2" :key="item">
        <div class="upload-box" v-if="item===1">
          <div class="upload-wrapper">
            <i v-if="status==='upload'" class="el-icon-upload" @click.stop="uploadFile"></i>
            <i v-else-if="status==='loading'" class="el-icon-loading" @click.stop="uploadFile"></i>
            <i v-else-if="status==='success'"  class="el-icon-success" @click.stop="uploadFile"></i>
            <i v-else-if="status==='error'"  class="el-icon-warning" @click.stop="uploadFile"></i>
            <p>Drag files here, <em>click upload</em>, <em>copy clipboard</em></p>
            <p>Only txt file can upload,and shouldn't exceed 1M</p>
          </div>
        </div>
        <div class="select-box" v-else-if="item === 2">
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%">
            <el-table-column label="Number">
              <template slot-scope="scope">{{ scope.row.number }}</template>
            </el-table-column>
            <el-table-column prop="Operation" label="Operation" width="120">
              <template slot-scope="scope">
                <el-button size="small" @click.stop="deleteItem(scope.row)" type="text">Delete</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div class="btns-box">
            <el-button @click.stop="cancel" type="info" icon="el-icon-arrow-left">Cancel</el-button>
            <el-button v-if="tableData.length" @click.stop="ok" type="primary" :icon="`${okLoading?'el-icon-loading':'el-icon-check'}`">OK</el-button>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import { insertContacts } from '@/utils/indexdb'
export default {
  name: 'translate',
  data() {
    return {
      status: 'upload',
      okLoading: false,
      tableData: [],
      dialog: false
    }
  },
  computed: {
    ...mapGetters([
      'fromPhone',
      'phones'
    ])
  },
  methods: {
    deleteItem(item) {
      const numbers = this.tableData
      for (let i = 0; i < numbers.length; i++) {
        const element = numbers[i];
        if (element.number === item.number) {
          numbers.splice(i, 1)
        }
      }
    },
    cancel() {
      this.numbers = []
      this.status = 'upload'
      this.$refs.carousel.prev()
    },
    ok() {
      if (this.fromPhone) {
        if (!this.okLoading) {
          this.okLoading = true
          const newContacts =  insertContacts(this.fromPhone.fullNumber, this.tableData)
          setTimeout(() => {
            this.tableData = []
            this.okLoading = false
            this.cancel()
            this.$store.dispatch('sms/SetContact', newContacts)
          }, 300)
        }
      } else {
        this.dialog = true
      }
    },
    confirmPhone() {
      const phones = this.$refs.tables.selection
      for (let i = 0; i < phones.length; i++) {
        const element = phones[i]
        insertContacts(element.fullNumber, this.tableData)
      }
      this.dialog = false
    },
    uploadFile() {
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = 'text/*'
      input.click()
      input.onchange = this._fileChange
    },
    _fileChange(event) {
      this.status = 'loading'
      var reader = new FileReader();
      reader.readAsText(event.path[0].files[0],'UTF-8');
      reader.onload = (e) => {
        setTimeout(() => {
          try {
            const result = e.currentTarget.result
            let newResult = result.replaceAll('\n', '\r\n')
            const lines = newResult.split('\r\n')
            const numbers = []
            for (let i = 0; i < lines.length; i++) {
              const line = lines[i];
              if (line !== '' && line.length < 50) {
                numbers.push({
                  number: line
                })
              }
            }
            this.status = 'success'
            if (numbers.length === 0) {
              this.status = 'upload'
              return
            }
            this.tableData = numbers
            setTimeout(() => {
              this.$refs.carousel.next()
            }, 500);
          } catch {
            this.status = 'error'
          }
        }, 800)
      }
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss">
.import-box {
  padding: 0 2px;
  height: 100%;
  .el-carousel__item h3 {
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }
  .el-carousel, .el-carousel__container {
    height: 100%;
  }
  .el-upload-dragger {
    border: none;
  }
  .upload-box {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .upload-wrapper {
      background-color: #fff;
      width: 360px;
      height: 180px;
      text-align: center;
      cursor: pointer;
      overflow: hidden;
      p {
        color: #606266;
        font-size: 14px;
        text-align: center;
        margin: 4px 0;
        em {
          color: #409eff;
          font-style: normal;
          font-size: 14px;
          text-align: center;
        }
      }
      .el-icon-upload, .el-icon-warning, .el-icon-success, .el-icon-loading {
        font-size: 67px;
        margin: 16px 0;
        line-height: 50px;
      }
      .el-icon-upload, .el-icon-loading {
        color: #C0C4CC;
      }
      .el-icon-warning {
        color: rgb(236, 52, 52);
      }
      .el-icon-success {
        color: #00796b;
      }
    }
  }
  .select-box {
    background-color: rgb(234, 234, 234);
    padding: 20px 10px;
    height: 100%;
    .btns-box {
      margin: 10px;
      display: flex;
      justify-content: space-between;
      button {
        flex: 1;
      }
    }
  }
}
</style>
      